<template>
  <div>
    <search-agent-renew-bill @submit="submitSearch" />

    <div class="iotplt-table-operation-buttons">
      <a-button type="primary" @click="exportData">导出</a-button>
    </div>

    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="agent_bill_id"
    >
      <div slot="no" slot-scope="no, record">
        <router-link
          :to="{ name: 'agent_consumption_bill_renew_order_info_commission',
                 params: { renew_order_id: record.agent_renew_order_id} }"
        >
          {{ no }}
        </router-link>
      </div>
    </a-table>
    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { findAgentConsumptionRenewBills, exportAgentConsumptionRenewBills } from '@/api/agent_consumption_bill'
import SearchAgentRenewBill from '@/views/agent_consumption_bills_commission/renew/Search'
import { formatBigNumber, formatCurrency } from '@/utils/filter'
import { exportExcel } from '@/api/excel'

export default {
  name: 'AgentPurchaseBillList',
  components: {
    Pagination,
    SearchAgentRenewBill
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowInfoModal: false,
      showingId: 0,
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  computed: {
    agentType() {
      return this.$store.getters.userAgentType
    },

    columns() {
      var columnArr = [
        {
          title: '订单批次',
          dataIndex: 'no',
          width: 150,
          scopedSlots: { customRender: 'no' }
        },
        {
          title: '订单时间',
          width: 200,
          dataIndex: 'created_at'
        },
        {
          title: '卡号数量',
          dataIndex: 'cards_count',
          width: 100,
          customRender: formatBigNumber
        },
        {
          title: '续期周期数',
          dataIndex: 'cycles',
          width: 100,
          customRender: formatBigNumber
        },
        {
          title: '订单金额(元)',
          dataIndex: 'total_fee',
          width: 100,
          customRender: formatCurrency
        },
        {
          title: '佣金金额(元)',
          dataIndex: 'commission_fee',
          width: 100,
          customRender: formatCurrency
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 100,
          ellipsis: true
        }
      ]

      return columnArr
    },

    agentId() {
      return this.$store.getters.userAgentId
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findAgentConsumptionRenewBills(Object.assign({ agent_id: this.agentId, booking_type: 1 }, this.query)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }

        this.loading = false
      })
    },

    exportData() {
      exportAgentConsumptionRenewBills(Object.assign({ agent_id: this.agentId, booking_type: 1 }, this.query)).then((res) => {
        if (res.code === 0 && res.data.is_direct_download) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>

